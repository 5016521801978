// Modules.
import preloader from "preloader";

export default function preload() {

    const loader = preloader({ xhrImages: false });

    loader.addImage("/images/products/adult-goth-reaper-costume/product-image.png");
    loader.addImage("/images/products/adult-goth-reaper-costume/background-image.jpg");

    loader.addImage("/images/products/adult-mummified-costume/product-image.png");
    loader.addImage("/images/products/adult-mummified-costume/background-image.jpg");

    loader.addImage("/images/products/bloody-vampire-mask/product-image.png");
    loader.addImage("/images/products/bloody-vampire-mask/background-image.jpg");

    loader.addImage("/images/products/giant-creepy-girl-decoration/product-image.png");
    loader.addImage("/images/products/giant-creepy-girl-decoration/background-image.jpg");

    loader.addImage("/images/products/grey-crimped-wig/product-image.png");
    loader.addImage("/images/products/grey-crimped-wig/background-image.jpg");

    loader.addImage("/images/products/life-sized-poseable-skeleton/product-image.png");
    loader.addImage("/images/products/life-sized-poseable-skeleton/background-image.jpg");

    loader.addImage("/images/products/light-up-giant-spider/product-image.png");
    loader.addImage("/images/products/light-up-giant-spider/background-image.jpg");

    loader.addImage("/images/products/light-up-trapped-soul-tombstone/product-image.png");
    loader.addImage("/images/products/light-up-trapped-soul-tombstone/background-image.jpg");

    loader.addImage("/images/products/skeleton-spider-decoration/product-image.png");
    loader.addImage("/images/products/skeleton-spider-decoration/background-image.jpg");

    loader.addImage("/images/products/towering-pumpkin-creep-animatronic/product-image.png");
    loader.addImage("/images/products/towering-pumpkin-creep-animatronic/background-image.jpg");

    loader.load();
}