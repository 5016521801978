<template>
    <section id="home">
        <Pano ref="pano" />
    </section>
</template>

<script>

// Components
import Pano from "@/components/Pano";

export default {
    name: "Home",
    components: { Pano }
}

</script>

<style lang="scss" scoped>

#home {
    height: 100%;
}

</style>