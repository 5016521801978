export default {
    instructions: {
        how_to: "Ready to take your scary to the next level? Shop our scariest supplies, decor and animatronics, they\'re sure to shock anyone that approaches your house this Halloween!",
        tap_here: "Tap Here",
        explore: "Explore Now"
    },
    hotspots: {
        products: [
            {
                pos: { "yaw": 5.17, "pitch": 0.7 },
                category: "decoration",
                scare_factor: 2,
                title: "Skeleton Spider Decoration",
                description: "What\'s more eerie than skeletons and spiders? The two, combined. With its long, jointed legs and beady black eyes, this skeleton spider decoration makes a haunting impression.",
                src: "/images/products/skeleton-spider-decoration/product-image.png",
                background: "/images/products/skeleton-spider-decoration/background-image.jpg",
                href: "https://www.partycity.com/skeleton-spider-decoration-21in-x-12in-923447?extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Skeleton Spider Decoration"
            },
            {
                pos: { "yaw": 4.82, "pitch": 0.27 },
                category: "decoration",
                scare_factor: 2,
                title: "Life–Sized Poseable Skeleton",
                description: "Everyone needs a skeleton to pull out of the closet! With ten points of articulation and a moveable head, this five-foot skeleton knows how to hold a pose.",
                src: "/images/products/life-sized-poseable-skeleton/product-image.png",
                background: "/images/products/life-sized-poseable-skeleton/background-image.jpg",
                href: "https://www.partycity.com/life-size-poseable-skeleton-5ft---halloween-decoration-942461.html?cgid=halloween-decorations-skeletons-skulls&fromplp=true&extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Life-Sized Poseable Skeleton"
            },
            {
                pos: { "yaw": 7.45, "pitch": -0.6 },
                category: "decoration",
                scare_factor: 3,
                title: "Giant Creepy Girl Decoration",
                description: "Have this seven-foot decoration looming over your porch with her long black hair, tattered clothing and a perfectly possessed look in her eyes.",
                src: "/images/products/giant-creepy-girl-decoration/product-image.png",
                background: "/images/products/giant-creepy-girl-decoration/background-image.jpg",
                href: "https://www.partycity.com/giant-creepy-girl-decoration-843461.html?cgid=halloween-decorations-props&fromplp=true&extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Creepy Possessed Girl Giant Hanging Prop, White, 7-ft, Indoor/Outdoor Decoration for Halloween"
            },
            {
                pos: { "yaw": 8.8, "pitch": -0.6 },
                category: "decoration",
                scare_factor: 3,
                title: "Giant Creepy Girl Decoration",
                description: "Have this seven-foot decoration looming over your porch with her long black hair, tattered clothing and a perfectly possessed look in her eyes.",
                src: "/images/products/giant-creepy-girl-decoration/product-image.png",
                background: "/images/products/giant-creepy-girl-decoration/background-image.jpg",
                href: "https://www.partycity.com/giant-creepy-girl-decoration-843461.html?cgid=halloween-decorations-props&fromplp=true&extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Creepy Possessed Girl Giant Hanging Prop, White, 7-ft, Indoor/Outdoor Decoration for Halloween"
            },
            {
                pos: { "yaw": 5.5, "pitch": 0.45 },
                category: "decoration",
                scare_factor: 2,
                title: "Light–Up Giant Spider",
                description: "There\'s just something about a set of beady red eyes that glow with the flip of a switch... Make Halloween creepy-crawly with this furry six-foot spider.",
                src: "/images/products/light-up-giant-spider/product-image.png",
                background: "/images/products/light-up-giant-spider/background-image.jpg",
                href: "https://www.partycity.com/light-up-giant-spider-457206.html?extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Light-Up Giant Spider",
                category_modifier: "black"
            },
            {
                pos: { "yaw": 6, "pitch": -0.4 },
                category: "costume",
                scare_factor: 1,
                title: "Grey Crimped Wig",
                description: "Add a ghostly air to your look with this wig. It combines long crimped sections and braids with strips of tattered gauze.",
                src: "/images/products/grey-crimped-wig/product-image.png",
                background: "/images/products/grey-crimped-wig/background-image.jpg",
                href: "https://www.partycity.com/grey-crimped-wig-965016.html?extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Grey Crimped Wig"
            },
            {
                pos: { "yaw": 6.05, "pitch": 0 },
                category: "costume",
                scare_factor: 2,
                title: "Adult Mummified Costume",
                description: "Go full mummy with this modern twist on a classic Halloween favorite, complete with a hood and face covering.",
                src: "/images/products/adult-mummified-costume/product-image.png",
                background: "/images/products/adult-mummified-costume/background-image.jpg",
                href: "https://www.partycity.com/adult-mummified-costume-P965055.html?extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Adult Mummified Costume"
            },
            {
                pos: { "yaw": 6.6, "pitch": -0.4 },
                category: "costume",
                scare_factor: 1,
                title: "Adult Goth Reaper Costume",
                description: "The reaper is here. And it looks like this: a black hooded crop top, a silver chain harness, and of course, skeleton-print leggings.",
                src: "/images/products/adult-goth-reaper-costume/product-image.png",
                background: "/images/products/adult-goth-reaper-costume/background-image.jpg",
                href: "https://www.partycity.com/adult-goth-reaper-costume-P964949.html?dwvar_P964949_size=S&extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Adult Goth Reaper Costume"
            },
            {
                pos: { "yaw": 8.39, "pitch": 0.25 },
                category: "decoration",
                scare_factor: 2,
                title: "Light–Up Trapped Soul Tombstone",
                description: "Add this trapped soul to your own outdoor graveyard. The light-up candles reveal a tortured face that sends a shiver down the spine.",
                src: "/images/products/light-up-trapped-soul-tombstone/product-image.png",
                background: "/images/products/light-up-trapped-soul-tombstone/background-image.jpg",
                href: "https://www.partycity.com/light-up-trapped-soul-tombstone-14.75in-x-22in-941710.html?extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Light-Up Trapped Soul Tombstone"
            },
            {
                pos: { "yaw": 6.6, "pitch": -0.65 },
                category: "costume",
                scare_factor: 3,
                title: "Bloody Vampire Mask",
                description: "Nothing says nightfall like the vampire. The blood-soaked teeth of this mask will drain the blood from anyone\'s face.",
                src: "/images/products/bloody-vampire-mask/product-image.png",
                background: "/images/products/bloody-vampire-mask/background-image.jpg",
                href: "https://www.partycity.com/adult-primeval-vampire-latex-mask---zagone-studios-942795.html?extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Bloody Vampire Mask"
            },
            {
                pos: { "yaw": 3.12, "pitch": -0.25 },
                category: "decoration",
                scare_factor: 3,
                title: "Towering Pumpkin Creep Animatronic",
                description: "Step up Halloween with this 12-foot animatronic. Whenever it detects motion, its fiery jack-o-lantern eyes come to life, and it growls grim warnings.",
                src: "/images/products/towering-pumpkin-creep-animatronic/product-image.png",
                background: "/images/products/towering-pumpkin-creep-animatronic/background-image.jpg",
                href: "https://www.partycity.com/animatronic-light-up-towering-pumpkin-creep-12ft-967575.html?extcmp=psm|pinterest|consideration|halloween|shoppable360|9.28.2023",
                alt: "Towering Pumpkin Creep Animatronic"
            },
        ]
    }
}