<template>
    <div ref="product" class="product">
        <div class="container">
            <ul class="scare-factor">
                <li v-for="(index) in scareFactor" :key="index">
                    <p 
                        class="skull"
                    >
                        💀
                    </p>
                </li>
            </ul>
            <p 
                :class="`category ${info.category_modifier}`"
            >
                {{ info.category }}
            </p>
        </div>
        <div class="content">
            <img class="product-image" :src="info.src" alt="Product image">
            <p 
                class="title cinta"
            >
                {{ info.title }}
            </p>
            <p 
                class="description"
            >
                {{ info.description }}
            </p>
            <a class="button" :href="info.href" target="_blank" rel="noopener" @click="buttonClick">
                Buy Now
            </a>
        </div>
        <img class="background" :src="info.background" alt="Product card background">
    </div>
</template>

<script>

export default {
    name: "Product",
    props: {
        info: { type: Object, default: () => {} }
    },
    computed: {
        scareFactor() {

            const factor = [];

            for ( let i = 0; i < this.info.scare_factor; i++ ) { factor.push(i); }

            return factor;
        }
    },
    methods: {
        buttonClick() { 
            
            gtag('event', 'buy_now_click', { 'buynow_product_name': this.info.title });
            this.$emit('click');
        }
    }
}

</script>

<style lang="scss" scoped>

@import "../styles/_variables.scss";

.product {
    width: 80%;
    max-width: 360px;
    height: 80%;
    max-height: 600px;
    position: relative;
    overflow: hidden;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 30px 20px;
}

.product-image {
    position: relative;
    width: 50%;
    height: auto;
    margin-bottom: 30px;
}

.background {
    position: absolute;
    width: 102%;
    height: 100%;
    top: 0;
    left: -2px;
    right: 0;
    bottom: 0;
    object-fit: cover;
}

.title {
    margin-bottom: 20px;
    color: color(orange);
    font-size: rem(24);
    line-height: 125%;
}

.description {
    text-align: center;
    margin-bottom: 30px;
    font-size: rem(15);
    line-height: 125%;
    color: color(White);
    padding: 15px;
}

.button {
    background-color: color(Orange);
    text-decoration: none;
    color: color(Black);
    padding: 11px 53px;
    border-radius: 100px;
    font-size: rem(20);
}

.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    position: absolute;
    top: 30px;
    padding: 0 20px;
}

.category {
    text-transform: uppercase;
    border: solid color(Orange) 1px;
    border-radius: 60px;
    color: color(White);
    padding: 2px 8px;
    line-height: 150%;
    font-size: rem(12);
}

.category.black {
    color: color(Black);
}

.scare-factor {
    display: flex;
}

.skull {
    font-size: rem(20);
}

.title,
.product-image,
.container,
.content {
    z-index: 1;
}

</style>