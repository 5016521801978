<template>
    <div ref="info" class="info">
        <div class="close" @click="close">
            <img src="/images/icons/close-black.svg" alt="Close button">
        </div>
        <Product v-if="activeIndex !== null" :info="products[activeIndex]" @click="close" />
    </div>
</template>

<script>

// Modules
import gsap from "gsap";

// Components
import Product from "@/components/Product.vue";

export default {
    name: "Info",
    components: { Product },
    props: {
        products: { type: Array, default: () => {} },
        activeIndex: { type: Number, default: null }
    },
    mounted() {

        gsap.set(this.$refs.info, { autoAlpha: 0, pointerEvents: "none" });

        this.$refs.info.addEventListener("click", event => {
            gsap.to(this.$refs.info, { autoAlpha: 0, pointerEvents: "none" });
            if ( event.target.className === "info" ) this.$emit("close");
        });
    },
    methods: {
        open() { gsap.to(this.$refs.info, { autoAlpha: 1, pointerEvents: "auto", delay: 0.5, duration: 0.5 }) },
        close() { 

            gsap.to(this.$refs.info, { autoAlpha: 0, pointerEvents: "none" });
            this.$emit("close");
        }
    }
}

</script>

<style lang="scss" scoped>

@import "../styles/_variables.scss";
@import "../styles/_mediaqueries.scss";

.info {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 5;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: color(White);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>