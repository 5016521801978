<template>
    <div id="app">
        <Home />
    </div>
</template>

<script>

// Components
import Home from "./views/Home.vue";

export default {
    name: "App",
    components: { Home }
}
    
</script>

<style lang="scss" scoped></style>
