
// Modules
import Vue from "vue"

// Styles
import "@/styles/index.scss";

// Components
import App from "./App.vue"

Vue.config.productionTip = false

new Vue({render: h => h(App)}).$mount("#app");
