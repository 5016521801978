<template>
    <div :id="`hotspot_${ id }`" ref="hotspot" :class="{ Hotspot: true, active, idle, ready }" @click="$emit('click')">
        <div class="pulse-wrapper">
            <div ref="wrapper" class="wrapper">
                <div class="ring" />
                <div class="ring" />
            </div>
        </div>
        <div v-if="bubble" class="bubble">
            <p>Tap Here</p>
        </div>
    </div>
</template>

<script>

// Modules.
import gsap from "gsap";

export default {
    name: "Hotspot",
    props: {
        bubble: { type: Boolean, default: false },
        id: { type: Number, default: null }
    },
    data() {
        return {
            active: false,
            idle: false,
            ready: false
        }
    },
    methods: {
        hide() {
            gsap.to(this.$refs.hotspot, { opacity: 0.25 });
            this.idle = true;
        },
        show() {
            gsap.to(this.$refs.hotspot, { opacity: 1 });
            this.deactivate();
        },
        setReady(ready) { this.ready = ready },
        deactivate() {
            this.active = false;
            this.idle = false;
        },
        activate() {
            this.active = true;
        }
    }
}

</script>

<style lang="scss" scoped>

    @import "../styles/_variables.scss";

    $pulse-duration: 2s;

    .Hotspot,
    .Hotspot:before,
    .Hotspot:after {
        border-radius: 50%;
    }

    .Hotspot {
        position: relative;
        width: 30px;
        height: 30px;
        cursor: pointer;
        pointer-events: none;
        z-index: 1;
    }

    .Hotspot.ready {
        pointer-events: auto;
    }

    .Hotspot:before,
    .Hotspot:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .Hotspot:before {
        width: 44%;
        height: 44%;
        z-index: 5;
        opacity: 0;
        transition: opacity 300ms ease;
    }

    .Hotspot.active:before {
        opacity: 1;
    }

    .Hotspot:after {
        width: 44%;
        height: 44%;
        transition: transform 300ms ease;
    }

    .Hotspot.active:after {
        transform: translateX(-50%) translateY(-50%) scale(1.75);
    }

    .Hotspot.active:after,
    .Hotspot.idle:after {
        animation: none;
    }

    .Hotspot .pulse-wrapper {
        pointer-events: none;
    }

    .Hotspot .pulse-wrapper,
    .Hotspot .wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    .Hotspot .wrapper {
        background-color: color(White);
        transition: transform 0.3s ease;
    }

    .Hotspot .wrapper:before,
    .Hotspot .wrapper:after,
    .Hotspot .ring {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    .Hotspot .wrapper:before,
    .Hotspot .wrapper:after {
        content: "";
        width: 34%;
        height: 2px;
        background-color: color(Black);
    }

    .Hotspot .wrapper:before {
        transform: translate(-50%, -50%);
    }

    .Hotspot .wrapper:after {
        transform: translate(-50%, -50%) rotate(90deg);
    }

    .Hotspot .ring {
        width: 100%;
        height: 100%;
        border: 1px solid color(White);
        border-radius: inherit;
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) translateZ(0);
        pointer-events: none;
    }

    .Hotspot .ring:nth-child(1) {
        width: 100%;
        height: 100%;
    }

    .Hotspot .ring:nth-child(2) {
        width: 130%;
        height: 130%;
    }

    .Hotspot:hover .ring:nth-child(1),
    .Hotspot:hover .ring:nth-child(2) {
        width: 100%;
        height: 100%;
    }

    .Hotspot:not(.active):not(.idle) .ring:nth-child(1) {
        animation: pulse $pulse-duration linear infinite;
    }

    .Hotspot:not(.active):not(.idle) .ring:nth-child(2) {
        animation: pulse $pulse-duration linear infinite calc($pulse-duration / 3);
    }

    @keyframes idle-pulse {
        0%   { transform: scale(1);   }
        100% { transform: scale(0.8); }
    }

    @keyframes pulse {
        0%   { width: 100%; height: 100%; opacity: 0; }
        33%  { width: 130%; height: 130%; opacity: 1; }
        66%  { width: 160%; height: 160%; opacity: 1; }
        100% { width: 190%; height: 190%; opacity: 0; }
    }

    .bubble {
        position: absolute;
        bottom: calc(100% + 20px);
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: color(White);
        border-radius: 17px;
        width: 137px;
        height: 56px;
        opacity: 0;
        animation: bounce 2500ms linear infinite;
        transition: opacity 500ms ease;
    }

    .Hotspot.ready .bubble {
        opacity: 1;
    }

    @keyframes bounce {
        0% {
            transform: translateX(-50%) translateY(0);
        }
        10% {
            transform: translateX(-50%) translateY(-10px);
        }
        20% {
            transform: translateX(-50%) translateY(0);
        }
        30% {
            transform: translateX(-50%) translateY(-10px);
        }
        40% {
            transform: translateX(-50%) translateY(0);
        }
    }

    .bubble:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        width: 10px;
        height: 10px;
        background-color: color(White);
        transform: translateX(-50%) translateY(-60%) rotate(45deg);
    }

    .bubble p {
        font-size: rem(20);
        color: color(Black);
        line-height: 150%;
    }

</style>