<template>
    <div ref="pano" class="pano">
        <div ref="instructions" class="instructions">
            <div ref="instructionsContainer" class="container">
                <img class="background" src="/images/instructions-background.jpg" alt="Instructions background image">
                <img class="logo-stacked" src="/images/logo-stacked.png" alt="Party city logo stacked">
                <p>{{ instructions }}</p>
                <Button 
                    @click="closeInstructions()"
                >
                    Explore Now
                </Button>
            </div>
        </div>
        <Hotspot
            v-for="(hotspot, index) in products"
            :id="index"
            ref="hotspot"
            :key="index"
            :bubble="index === 10 && !hasSeenInfo"
            @click="openInfo(index)"
        />
        <Info ref="info" :products="products" :active-index="activeIndex" @close="closeInfo()" />
        <img class="logo" src="/images/logo.png" alt="Party City Logo">
        <a v-if="!infoOpen" class="shop-now bold" href="https://www.partycity.com/halloween" target="_blank" @click="buttonClick">
            Shop Now
        </a>
    </div>
</template>

<script>

// Modules
import * as Marzipano from "marzipano";
import gsap from "gsap";

// Components
import Hotspot from "@/components/Hotspot.vue";
import Info from "@/components/Info.vue";

// Data
import json from "../data/site";
const { instructions, hotspots } = json;

// Misc
import preload from "@/preload";

export default {
    name: "Pano",
    components: { 
        Hotspot, 
        Info 
    },
    data() {
        return {
            infoOpen: false,
            hasSeenInfo: false,
            activeIndex: 0,
            instructions: instructions.how_to,
            products: hotspots.products
        }
    },
    mounted() {

        gsap.set([this.$refs.instructions, this.$refs.zoom], { autoAlpha: 0 });
        gsap.set(this.$refs.instructionsContainer, { autoAlpha: 0, y: 25, pointerEvents: "none" });

        const opts = { controls: { mouseViewMode: "drag" } };
        this.viewer = new Marzipano.Viewer(this.$refs.pano, opts);

        const fov           = window.innerWidth <= 768 ? 70 : 90;
        const fovOffset     = window.innerWidth <= 768 ? 35 : 45;
        const source        = Marzipano.ImageUrlSource.fromString("/images/360-scene.jpg");
        const geometry      = new Marzipano.EquirectGeometry([{ width: 4096 }]); // Equal to image"s width in px.
        this.limiterDefault = Marzipano.RectilinearView.limit.traditional(1024, (fov * Math.PI / 140));
        this.limiterZoomed  = Marzipano.RectilinearView.limit.traditional(1024, ((fov - fovOffset) * Math.PI / 180));
        const initialView   = { yaw: 2.5, pitch: -0.4 };
        const view          = new Marzipano.RectilinearView(initialView, this.limiterDefault);
        const scene         = this.viewer.createScene({ source, geometry, view, pinFirstLevel: true });

        for (let i = 0; i < this.products.length; i++) {
            scene.hotspotContainer().createHotspot(this.$refs.hotspot[i].$el, this.products[i].pos);
        }

        scene.switchTo();

        this.stage = document.getElementsByTagName("canvas")[0];
        gsap.set(this.stage, { pointerEvents: "none" });

        this.$refs.instructions.addEventListener("click", e => {
            if ( e.target === this.$refs.instructions ) this.closeInstructions();
        });

        preload();

        this.openInstructions();
    },
    methods: {
        openInfo(index) {

            const product = this.products[index];

            gtag('event', 'hotspot_click', { 'hotspot_product_name': product.title });

            this.infoOpen = true;
            this.activeIndex = index;

            gsap.to(this.stage, { opacity: 0.4, pointerEvents: "none", duration: 0.5, ease: "sine.out" });

            this.clearIdleMovement();
            this.$refs.info.open();
            this.$refs.hotspot[index].activate();
            this.$refs.hotspot.map(hotspot => hotspot.hide());

            if ( !this.hasSeenInfo ) this.hasSeenInfo = true;
        },
        closeInfo() {

            this.infoOpen = false;
            this.activeIndex = null;

            gsap.to(this.stage, { opacity: 1, pointerEvents: "auto", duration: 0.5, ease: "sine.out" });

            this.setIdleMovement();
            this.$refs.hotspot.map(hotspot => hotspot.show());
        },
        closeInstructions() {

            this.$refs.hotspot.map(hotspot => hotspot.show());

            gsap.to(this.stage, { opacity: 1, duration: 0.5, ease: "sine.out" });
            gsap.to(this.$refs.instructions, { autoAlpha: 0, duration: 0.5, ease: "sine.out", onComplete: () => {
                this.$refs.instructions.remove();
            }});

            this.viewer.lookTo({ yaw: 3.12, pitch: -0.25 }, { transitionDuration: 3000 }, () => {

                gsap.set(this.stage, { pointerEvents: "auto" });

                this.$refs.hotspot.map(hotspot => hotspot.setReady(true));
                this.setIdleMovement();
            });
        },
        openInstructions() {

            gsap.to(this.stage, { opacity: 0.4, duration: 0.5, ease: "sine.out" });
            gsap.to(this.$refs.instructions, { autoAlpha: 1, duration: 0.5, delay: 0.25, ease: "sine.out" });
            gsap.to(this.$refs.instructionsContainer, { autoAlpha: 1, y: 0, pointerEvents: "auto", duration: 0.5, delay: 0.5, ease: "sine.out" });

            this.$refs.hotspot.map(hotspot => hotspot.hide());
        },
        setIdleMovement() {

            this.viewer.setIdleMovement(5000, Marzipano.autorotate({
                yawSpeed: 0.025,
                targetPitch: 0.3,
            }));
        },
        clearIdleMovement() { this.viewer.setIdleMovement(Infinity) },
        buttonClick() { 
            
            gtag('event', 'shop_now_click', { 'behaviour': 'Link clicked' });
        }
    }
}

</script>

<style lang="scss" scoped>

@import "../styles/_variables.scss";
@import "../styles/mediaqueries.scss";


.pano {
    height: 100%;
    position: relative;
}

.pano h1 {
    color: color(White);
    font-size: rem(40);
}

.logo,
.shop-now {
    position: absolute;
    z-index: 1;
}

.shop-now {
    right: 20px;
    top: 20px;
    font-size: rem(20);
    color: color(white);

    @include mobile-up {
        right: 28px;
        top: 28px;
    }
}

.logo {
    width: 150px;
    height: auto;
    left: 28px;
    top: 15px;

    @include mobile-up {
        width: 200px;
    }
}

.instructions {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    align-items: center;
}

.instructions,
.instructions .container {
    display: flex;
    justify-content: center;
}

.instructions .container {
    position: relative;
    height: 80%;
    max-height: 530px;
    width: 80%;
    max-width: 360px;
    padding: 50px;
    background-color: color(Black);
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 60px;
    overflow: hidden;
    margin-top: 50px;

    @include tablet-up {
        margin-top: 0;
    }
}

.instructions .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.instructions p {
    color: color(White);
    font-size: rem(16);
    line-height: 1.5;
    width: 280px;
    margin-bottom: 40px;
}

.instructions button {
    background-color: color(Orange);
    border: none;
    padding: 6px 22px;
    border-radius: 62px;
    cursor: pointer;
    font-size: rem(16);
}

.instructions .logo-stacked {
    width: 188px;
    margin-bottom: 50px;
}

.instructions .logo-stacked,
.instructions button,
.instructions p {
    z-index: 1;
}

</style>